<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="900"
  >
    <v-card>
      <v-card-title class="text-h5 font-weight-bold">
        {{ $t('cookieconsentdialog.unsereWebseiteVerwendetCookies') }}
      </v-card-title>
      <v-card-text class="pb-0">
        <p class="text--primary">
          {{ $t('cookieconsentdialog.wirSetzenCookies1') }}<a @click="openPrivacyDialog">{{ $t('cookieconsentdialog.datenschutzbestimmungen1') }}</a> .
        </p>
        <v-row
          class="mt-n5 cookies-consent-actions"
        >
          <v-checkbox
            v-model="essentialCookiesEnabled"
            disabled
            class="mr-5 ml-2"
            :label="$t('cookieconsentdialog.essenzielle')"
          />
          <v-checkbox
            v-model="analysisCookiesEnabled"
            class="mr-5"
            :label="$t('cookieconsentdialog.analyse')"
          />
          <a
            class="more-info-button"
            @click="moreInfo = !moreInfo"
          >
            {{ moreInfoText }}
            <v-icon>
              {{ moreInfoIcon }}
            </v-icon>
          </a>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            class="py-0"
          >
            <v-expand-transition>
              <v-card
                v-show="moreInfo"
                class="mx-auto mt-0 mb-6"
              >
                <v-card-text>
                  <h3 class="mb-5">
                    {{ $t('cookieconsentdialog.essenziell') }}
                  </h3>
                  <p class="my-0">
                    {{ $t('cookieconsentdialog.essenzielleCookiesWerden') }}
                  </p>
                  <v-divider class="my-5" />
                  <h3 class="mb-5">
                    {{ $t('cookieconsentdialog.analyse2') }}
                  </h3>
                  <p class="my-0">
                    {{ $t('cookieconsentdialog.mitDiesenCookies1') }}
                  </p>
                </v-card-text>
              </v-card>
            </v-expand-transition>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        class="py-0 mt-9 mb-3"
        style="margin-left: -0.8rem"
      >
        <v-row class="mt-n5 py-3">
          <v-col
            class="py-0 px-6"
            cols="12"
            md="4"
          >
            <v-btn
              color="primary"
              block
              depressed
              @click="allowAllcookies"
            >
              {{ $t('cookieconsentdialog.alleAkzeptieren') }}
            </v-btn>
          </v-col>
          <v-col
            class="pa-0"
            cols="12"
            md="4"
          >
            <v-btn
              color="green darken-1"
              block
              text
              @click="allowSelectedCookies"
            >
              {{ $t('cookieconsentdialog.ausgewhlteAkzeptieren') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <privacy-dialog
      ref="privacyDialog"
    />
  </v-dialog>
</template>

<script>
  export default {
    name: 'CookieConsentDialog',
    components: {
      PrivacyDialog: () => import('@/components/PrivacyDialog.vue'),
    },
    data () {
      return {
        dialog: false,
        essentialCookiesEnabled: true,
        analysisCookiesEnabled: false,
        moreInfo: false,
        moreInfoIcon: 'mdi-menu-down',
        moreInfoText: 'mehr Infos',
      }
    },
    watch: {
      moreInfo: function (val) {
        if (val) {
          this.moreInfoText = 'weniger Infos'
          this.moreInfoIcon = 'mdi-menu-up'
        } else {
          this.moreInfoText = 'mehr Infos'
          this.moreInfoIcon = 'mdi-menu-down'
        }
      },
    },
    methods: {
      open () {
        this.dialog = true
      },
      close () {
        this.dialog = false
      },
      allowAllcookies () {
        this.removeAllCookies()
        this.$cookies.set('wantsCookies', true, '365d')

        this.$cookies.set('wantsEssentialCookies', true, '365d')
        this.$cookies.set('wantsAnalysisCookies', true, '365d')
        this.closeAndReloadPage()
      },
      allowSelectedCookies () {
        this.removeAllCookies()
        this.$cookies.set('wantsCookies', true, '365d')

        this.$cookies.set('wantsEssentialCookies', this.essentialCookiesEnabled, '365d')
        this.$cookies.set('wantsAnalysisCookies', this.analysisCookiesEnabled, '365d')
        this.closeAndReloadPage()
      },
      removeAllCookies () {
        this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
      },
      closeAndReloadPage () {
        this.close()
        location.reload()
      },
      openPrivacyDialog () {
        this.$refs.privacyDialog.open()
      },
    },
  }
</script>

<style>
  a::after {
    content: ' ';
  }

  a::before{
    content: ' ';
  }
</style>
