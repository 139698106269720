<template>
  <div>
    <anonymous-page-bar />
    <br>
    <br>
    <v-main>
      <slot />
    </v-main>
    <br>
    <br>
    <anonymous-page-footer />
  </div>
</template>

<script>
  import AnonymousPageBar from '@/layouts/AnonymousPageBar.vue'
  import AnonymousPageFooter from '@/layouts/AnonymousPageFooter.vue'

  export default {
    name: 'AnonymousPageLayout',
    components: {
      AnonymousPageBar,
      AnonymousPageFooter,
    },
  }
</script>
