<template>
  <div>
    <v-app-bar
      id="app-bar-anonymous"
      color="white"
      :prominent="$vuetify.breakpoint.mdAndUp"
      fixed
      flat
    >
      <v-container
        class="v-app-bar-content"
      >
        <v-row>
          <!-- Start Logo -->
          <v-col
            cols="8"
            md="3"
            class="col-logo"
          >
            <v-toolbar-title>
              <logo-link />
            </v-toolbar-title>
          </v-col>
          <!-- End Logo -->
          <!-- Start Menu Items -->
          <v-col
            v-if="$vuetify.breakpoint.mdAndUp"
            cols="7"
            class="pt-8"
          >
            <div class="links-container">
              <router-link
                v-for="item in menuItems"
                :key="item.title"
                :to="item.link"
                class="w-1 mx-4 link"
              >
                <div class="font-weight-black black--text navbar-text customer-navbar-items">
                  {{ item.title }}
                </div>
              </router-link>
            </div>
          </v-col>
          <!-- End Menu Items -->
          <!-- Start User Menu Items -->
          <v-col
            v-if="$vuetify.breakpoint.mdAndUp"
            cols="2"
            class="language-container"
          >
            <div class="locale-changer mt-2 locale-changer-space">
              <v-select
                v-model="$i18n.locale"
                :items="$i18n.availableLocales"
                dense
                class="languageSelectBar"
                @change="updateLocale()"
              >
                <template v-slot:selection="{ item }">
                  <v-img
                    :src="getImgUrl(item)"
                    alt="language"
                    max-width="40"
                    contain
                  />
                </template>
                <template v-slot:item="{ item }">
                  <v-img
                    :src="getImgUrl(item)"
                    alt="language"
                    max-width="40"
                    contain
                  />
                </template>
              </v-select>
            </div>
            <v-btn
              :to="userMenuItems[0].link"
              raised
              color="primary"
              class="float-right btn-login"
              @click="openLoginDialog"
            >
              <span>{{ userMenuItems[0].title }}</span>
            </v-btn>
          </v-col>
          <!-- End User Menu Items -->
          <!-- Start Hamburguer Menu Icon -->
          <v-col
            v-if="$vuetify.breakpoint.smAndDown"
            class="pt-6"
            cols="4"
          >
            <v-app-bar-nav-icon
              outlined
              class="float-right"
              color="primary"
              @click.stop="menuDrawer = !menuDrawer"
            />
          </v-col>
          <!-- End Hamburguer Menu Icon -->
        </v-row>
      </v-container>
    </v-app-bar>
    <!-- Start Navigation Menu Drawer -->
    <v-navigation-drawer
      v-model="menuDrawer"
      app
      bottom
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          active-class="primary--text text--accent-4"
        >
          <v-list-item
            v-for="item in menuItems"
            :key="item.title"
            :to="item.link"
          >
            <v-list-item-title class="text-button font-weight-black">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="installEvent"
            @click="openInstallPrompt"
          >
            <v-list-item-title class="text-button font-weight-black">
              {{ $t('layouts.core.appbaranonymous.install') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <div class="locale-changer mt-2">
              <v-select
                v-model="$i18n.locale"
                :items="$i18n.availableLocales"
                dense
                class="languageSelectBar"
              >
                <template v-slot:selection="{ item }">
                  <v-img
                    :src="getImgUrl(item)"
                    alt="language"
                    max-width="50"
                    contain
                  />
                </template>
                <template v-slot:item="{ item }">
                  <v-img
                    :src="getImgUrl(item)"
                    alt="language"
                    max-width="50"
                    contain
                  />
                </template>
              </v-select>
            </div>
          </v-list-item>
          <v-list-item
            class="primary-list-item"
            :to="userMenuItems[0].link"
            @click="openLoginDialog"
          >
            <v-list-item-title class="font-weight-black white--text">
              {{ userMenuItems[0].title }}
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <!-- End Navigation Menu Drawer -->
    <login-dialog-component
      ref="loginDialog"
    />
  </div>
</template>

<script>
  import LoginDialogComponent from '@/views/anonymousPages/components/LoginDialogComponent.vue'
  import LogoLink from '../components/LogoLink.vue'

  export default {
    components: {
      LogoLink,
      LoginDialogComponent,
    },
    data () {
      return {
        menuDrawer: false,
        sideNav: false,
      }
    },
    computed: {
      menuItems () {
        return [
        { title: this.$t('layouts.core.appbaranonymous.overview'), link: '/overview' },
        { title: this.$t('layouts.core.appbaranonymous.faq'), link: '/faqs' },
        { title: this.$t('layouts.core.appbaranonymous.prices'), link: '/prices' },
        { title: this.$t('layouts.core.appbaranonymous.aboutUs'), link: '/whoWeAre' },
        { title: this.$t('layouts.core.appbaranonymous.contact'), link: '/contact-us' },
      ]
},
      userMenuItems () {
        return [
        { title: this.$t('layouts.core.appbaranonymous.login'), link: '/login', icon: 'mdi-login' },
        { title: this.$t('layouts.core.appbaranonymous.home'), link: {name: 'Contacts'}, icon: 'mdi-home' },
      ]
},
      installEvent () {
        return this.$store.state.settingsModule.installEvent
      },
    },
    methods: {
      openLoginDialog () {
        this.$refs.loginDialog.open()
      },
      getImgUrl (lang) {
        return require('../assets/' + lang + '.webp')
      },
      openInstallPrompt () {
        this.installEvent.prompt()
      },
      updateLocale () {
        localStorage.setItem('locale', this.$root.$i18n.locale)
      },
    },
  }
</script>

<style>
  .pe-pro-seal {
    display: block;
  }
  .languageSelectBar.v-text-field > .v-input__control > .v-input__slot:before {
    border-style: none;
  }
  .languageSelectBar.v-text-field > .v-input__control > .v-input__slot:after {
    border-style: none;
  }
  .mdi-menu-down::before {
    margin-left: 0rem !important;
  }

  .links-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }

  .link:hover .navbar-text{
    color: #329c36 !important;
  }

  .router-link-active .navbar-text{
    color: #329c36 !important;
  }

  .language-container {
    padding-top: 2.2rem;
    display: flex;
    padding-left: 0;
    margin-left: -2rem;
  }

  .language-container.col.col-2 .btn-login {
    margin-top: 3px;
  }

  .language-container .locale-changer-space {
    margin-right: 1.5rem;
  }

  @media only screen and (min-width: 1904px ) {
    .navbar-text {
      font-size: 1.2rem !important;
    }
  }

  @media only screen and (max-width: 1904px ) {
    .navbar-text {
      font-size: 0.9rem !important;
    }
  }

  @media only screen and (max-width: 1264px ) {
    .navbar-text {
      font-size: 0.85rem !important;
    }
  }

  @media only screen and (max-width: 900px ) {
    .languageSelectBar .v-select__slot {
      max-width: 35%;
    }
    .pe-pro-seal {
      display: none;
    }
  }

  @media only screen and (min-height: 1150px ) {
    .v-application--wrap {
      min-height: 100% !important;
    }
  }
</style>
