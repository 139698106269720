<template>
    <v-form
      ref="loginForm"
      @submit.prevent="onLogin"
    >
      <v-container class="px-5 pb-5">
        <v-row>
          <v-col cols="12">
            <v-img
              :src="logoPictureSource"
              height="100"
              contain
            />
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <v-radio-group
              v-model="loginAs"
              class="black-label"
              :label="$t('views.anonymouspages.logindialogcomponent.einloggenAls')"
              :column="$vuetify.breakpoint.xsOnly"
              :row="$vuetify.breakpoint.smAndUp"
              dense
              @change="onChangeLoginAs"
            >
              <v-radio
                :label="$t('views.anonymouspages.logindialogcomponent.kunde')"
                value="customer"
              />
              <v-radio
                :label="$t('views.anonymouspages.logindialogcomponent.vertrauensperson')"
                value="confidant"
              />
            </v-radio-group>
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="pt-0"
          >
            <v-text-field
              v-model="email"
              :label="$t('views.anonymouspages.logindialogcomponent.emailadresse')"
              :rules="[validations.required($t('views.anonymouspages.requestpasswordchangekeycomponent.emailRequired')), validations.email($t('validations.email'))]"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="pt-0"
          >
            <v-text-field
              v-if="loginAs === customer"
              v-model="password"
              maxlength="100"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              :label="$t('views.anonymouspages.logindialogcomponent.passwort')"
              :rules="[validations.required($t('views.anonymouspages.requestpasswordchangekeycomponent.passwordRequired')),
                         validations.minLength(8, $t('validations.maxLength8'))]"
              @click:append="() => (showPassword = !showPassword)"
            />
            <v-text-field
              v-if="loginAs === confidant"
              v-model="pin"
              :label="$t('views.anonymouspages.logindialogcomponent.pin')"
              type="number"
              :rules="[validations.required('Die PIN darf nicht leer sein'), validations.checkPositiveNumber($t('validations.checkPositiveNumber'))]"
              onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))"
            />
          </v-col>
          <v-col
            v-if="loginAs === confidant"
            cols="12"
            class="pt-0"
          >
            <v-text-field
              v-model="customerId"
              :label="$t('views.anonymouspages.logindialogcomponent.id')"
              type="number"
              :rules="[validations.required('Die ID darf nicht leer sein'), validations.checkPositiveNumber($t('validations.checkPositiveNumber'))]"
              onkeydown="javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))"
            />
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <v-btn
              :disabled="(loginAs === 'customer' && (!validations.isEmail(email) || !validations.isPassword(password))) || (loginAs === 'confidant' && (!validations.isEmail(email) || !validations.isCheckPositiveNumber(pin) || !validations.isCheckPositiveNumber(customerId)))"
              type="submit"
              block
              depressed
              color="primary"
            >
              {{ $t('views.anonymouspages.logindialogcomponent.login') }}
            </v-btn>
            <v-btn
              v-if="loginAs === customer"
              block
              outlined
              color="primary"
              @click="onClickingRegistration"
            >
              {{ $t('views.anonymouspages.logindialogcomponent.registrieren') }}
            </v-btn>
            <div
              class="mx-1"
            >
              <a
                v-if="loginAs === customer"
                @click="openRequestPasswordChangeKeyDialog"
              >{{ $t('views.anonymouspages.logindialogcomponent.passwortVergessen') }}</a>
            </div>
            <div
              v-if="loginAs === confidant"
              class="mt-3 mx-1"
            >
              <a
                @click="openRequestPasswordOrIdForgottenDialog"
              >{{ $t('views.anonymouspages.logindialogcomponent.pinOderIdVergessen') }}</a>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <request-password-change
        ref="requestPasswordChangeDialog"
      />
      <request-password-or-id-forgotten
        ref="requestPasswordOrIdForgottenDialog"
      />
      <suspend-account-dialog
        ref="suspendAccountDialog"
      />
    </v-form>
</template>

<script>
import RequestPasswordChange from '@/views/anonymousPages/components/RequestPasswordChangeKeyComponent.vue'
import RequestPasswordOrIdForgotten from '@/views/anonymousPages/components/RequestPasswordOrIdForgottenComponent.vue'
import SuspendAccountDialog from '@/views/anonymousPages/components/SuspendedAccountDialogComponent.vue'
import validations from '@/components/formUtils/Validations'
import router from '@/router'
import LoginService from '@/services/LoginService'
import PublicService from '@/services/PublicService'
import NotificationService from '@/services/NotificationService'

export default {
  name: 'LoginFormComponent',
  components: {
    RequestPasswordChange,
    RequestPasswordOrIdForgotten,
    SuspendAccountDialog,
  },
  data () {
    return {
      customer: 'customer',
      confidant: 'confidant',
      loginAs: 'customer',
      showPassword: false,
      email: '',
      password: '',
      pin: '',
      customerId: '',
      validations,
      router,
      MAX_LOGIN_ATTEMPTS: 5,
    }
  },
  computed: {
    logoPictureSource () {
      const locale = this.$root.$i18n.locale
      if (locale === 'pt' || locale === 'ptBR') { return require('@/assets/emcomyLogo-pt.webp') }
      return require('@/assets/emcomyLogo.svg')
    },
  },
  methods: {
    onLogin (index) {
      const loginDto = {
        email: this.email,
        password: this.password,
        pin: this.pin,
        customerId: this.customerId,
      }
      LoginService.loginUser(loginDto)
        .then(response => {
          sessionStorage.token = response.data.token
          sessionStorage.name = response.data.name
          sessionStorage.isAdmin = response.data.admin
          sessionStorage.isCustomer = response.data.isCustomer
          if (response.data.required2FA === true) {
            PublicService.generate2FA(this.email)
            this.$store.commit('loginModule/setLoginDto', loginDto)
            sessionStorage.required2FA = "true"

            let currentDate = new Date();
            currentDate.setMinutes(currentDate.getMinutes() + 1);
            sessionStorage.resend2fa = currentDate
            this.router.push('/2fa')
          } else if (response.data.admin === true) {
            this.router.push('/admin')
          } else if (response.data.isCustomer === true) {
            this.router.push({ name:'Contacts' })
          } else {
            this.router.push({ name: 'EmergencyNotification' })
          }
        })
        .catch(error => {
          if (error.response.status === 404) {
            NotificationService.error(this.$t('views.customerpages.settings.updatepassworddialogcomponent.unknownUser'))
            PublicService.isEmailVerified(this.email)
              .then((isVerified) => {
                if (isVerified.data && loginDto.password !== '') {
                  PublicService.increaseLoginAttempts(this.email)
                    .then((response) => {
                      if (response.data === this.MAX_LOGIN_ATTEMPTS) {
                        this.openSuspendAccountDialog()
                      }
                    })
                }
              })
          } else if (error.response.status === 409) {
            this.openSuspendAccountDialog()
          } else {
            NotificationService.error(error.message)
          }
        })
    },
    onChangeLoginAs () {
      this.email = ''
      this.password = ''
      this.pin = ''
      this.customerId = ''
      this.$refs.loginForm.resetValidation()
    },
    onClickingRegistration () {
      this.router.push('/register')
    },
    validateForms () {
      if (this.loginAs === 'customer' && this.email !== '' && this.password !== '') {
        return true
      } else return this.loginAs === 'confidant' && this.email !== '' && this.pin !== '' && this.customerId !== '';
    },
    openRequestPasswordChangeKeyDialog () {
      this.$emit('closeForm')
      this.$refs.requestPasswordChangeDialog.open()
    },
    openRequestPasswordOrIdForgottenDialog () {
      this.$emit('closeForm')
      this.$refs.requestPasswordOrIdForgottenDialog.open()
    },
    openSuspendAccountDialog () {
      this.$emit('closeForm')
      this.$refs.suspendAccountDialog.open()
    },
  },
}
</script>

<style scoped>

</style>
